<script setup lang="ts">
const { cart, step } = useCart()

function openModal() {
  if (step.value.index > 6)
    return

  usePromoModalOpen()
}

const hasCoupon = computed(() => cart.value && cart.value.coupon)
</script>

<template>
  <div
    v-if="step.index <= 6 || hasCoupon"
    class="flex items-center justify-between gap-4 p-4 xl:px-8 bg-neutral-variant-96 cursor-pointer"
    :class="hasCoupon ? 'text-primary-50' : 'text-primary-20'"
    @click.prevent="openModal"
  >
    <div class="flex items-center justify-between gap-4">
      <div class="flex items-center justify-center rounded-full">
        <Icon :name="hasCoupon ? 'tabler:discount-check' : 'tabler:discount-2'" class="size-6" />
      </div>

      <p class="text-sm xl:text-base font-bold leading-6">
        Cupom Trevo
      </p>
    </div>

    <button v-if="!hasCoupon" type="button" class="flex items-center gap-1 font-bold text-base leading-6">
      Inserir Cupom
    </button>

    <span v-else class="text-center font-bold max-w-[90px] break-all">
      {{ cart.coupon }}
    </span>
  </div>
</template>
