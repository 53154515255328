<script setup lang="ts">
import { CHECKOUT_STEPS } from '@/modules/checkout/types/checkout.enum'
import { EventName } from '@/modules/trevo/types/enum'
import type { ICart, ICartDetail } from '@/modules/cart/types/cart.d'
import type { IBranch, IBundleItem } from '@/modules/procedure/types/bundle.d'

defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  hideChangeBranch: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close'])

const { cart, bundleSelected, branchesSelected } = useCart()

const branchSelected = ref<IBranch[]>([])
function handleSelectBranch(selection: IBranch, options = { remove: false }) {
  if (options.remove) {
    const index = branchSelected.value.findIndex(
      selected => selected.laboratoryId !== selection.laboratoryId,
    )

    branchSelected.value.splice(index, 1)

    return
  }

  const laboratory = bundleSelected.value?.bundle?.find(({ laboratoryId }) => laboratoryId === selection?.laboratoryId)

  track(EventName.SelectBranch, {
    tagAtHome: selection.laboratoryBranchAtHome,
    tagNeedSchedule: selection.laboratoryBranchNeedSchedule,
    laboratoryName: laboratory?.laboratoryName,
    branchName: selection.laboratoryBranchName,
  })

  branchSelected.value.push(selection)
  branchesSelected.value = branchSelected.value
}

const { schedulesFormatted } = useSchedule()
function goToCheckout() {
  track(EventName.AddCart)

  const cartDetails: ICartDetail[] = bundleSelected.value.bundle.flatMap(({ procedures, laboratoryId, laboratoryLogo, laboratoryName }: IBundleItem) => {
    return procedures.map(procedure => ({ laboratoryId, ...procedure, laboratoryLogo, laboratoryName }))
  }).map((procedure) => {
    let procedureScheduleGroupIndex: any = 0
    for (const index in bundleSelected.value.scheduleGroups) {
      const searched = bundleSelected.value.scheduleGroups[index].scheduleGroups.find(p => p.procedureDisplaySlug === procedure.procedureDisplaySlug)

      if (searched) {
        procedureScheduleGroupIndex = Number.parseInt(index)
        break
      }
    }

    const branch: IBranch = branchSelected.value.find(({ groupIndex }: IBranch) => groupIndex === procedureScheduleGroupIndex) as IBranch

    let scheduleOptions = null

    if (procedure.needSchedule) {
      scheduleOptions = schedulesFormatted.value[branch.laboratoryBranchId]
    }

    return {
      labId: procedure.laboratoryId,
      labBranchId: branch.laboratoryBranchId,
      labName: procedure.laboratoryName,
      needPrescription: procedure.needPrescription,
      labLogo: procedure.laboratoryLogo,
      labBranchProcedureName: branch.laboratoryBranchName,
      labBranchProcedurePrice: procedure.price,
      procedureId: procedure.procedureId,
      displayNameId: procedure.procedureDisplayId,
      scheduleOptions,
      displayName: procedure.procedureName,
    }
  })

  const payload: ICart = {
    sessionId: useTrackGoogleSessionId().value,
    cartDetails,
    digitalJourney: true,
    origin: 'website',
    lastStep: CHECKOUT_STEPS.BUDGET,
    totalPrice: bundleSelected.value.bundlePrice,
    discount: bundleSelected.value.bundleDiscount,
  }

  cart.value = { ...cart.value, ...payload }

  const { isAuthenticated } = useAuth()

  if (!isAuthenticated.value)
    sessionStorage.setItem('Cart', JSON.stringify(payload))

  return navigateTo('/checkout')
}

const { schedules } = useSchedule()

const goToCheckoutDisabled = computed(() => {
  let needSchedule = false

  for (const group of bundleSelected.value.scheduleGroups) {
    if (group.scheduleGroups.find(({ procedureNeedSchedule }) => procedureNeedSchedule)) {
      needSchedule = true
    }
  }

  if (bundleSelected.value.scheduleGroups.length === branchSelected.value.length) {
    if (!needSchedule)
      return false

    if (!Object.values(schedules.value).length)
      return true

    for (const option of Object.values(schedules.value)) {
      if (
        !option.firstOption
        || !option.firstOption.date
        || !option.firstOption.time
      ) {
        return true
      }
    }

    return false
  }

  return true
})

function closeModal() {
  branchSelected.value = []

  schedules.value = {}

  emit('close')
}

interface IScheduleGroup {
  procedureDisplaySlug: string
  procedureName: string
}

interface IGroup {
  scheduleGroups: IScheduleGroup[]
  scheduleBlockDays: number
}

function laboratoryHasProcedure(labIndex: number, procedureDisplaySlug: string) {
  return !!bundleSelected.value.bundle[labIndex].procedures.find((procedure: any) => procedure.procedureDisplaySlug === procedureDisplaySlug)
}

function groupIsInLab(labIndex: number, group: IGroup) {
  for (const scheduleGroup of group.scheduleGroups) {
    if (laboratoryHasProcedure(labIndex, scheduleGroup.procedureDisplaySlug)) {
      return true
    }
  }

  return false
}

function filterGroupsByLaboratory(labIndex: number) {
  let groups = useCart().bundleSelected.value.scheduleGroups

  groups = groups.map((group, index: number) => ({ ...group, index }))

  return groups.filter(group => groupIsInLab(labIndex, group))
}
</script>

<template>
  <SideSheet :show="show" title="Onde você busca atendimento?" @close-modal="closeModal">
    <template v-if="show && Object.keys(bundleSelected).length" #content>
      <div class="relative h-full w-full overflow-x-hidden mb-8">
        <p class="text-primary-20 text-base font-normal leading-6 mt-2 mb-8 xl:px-8 px-4">
          Escolha a unidade e informe as datas que mais se encaixam em sua agenda para realizar os procedimentos, e nós
          iremos confirmar a disponibilidade com o laboratório.
        </p>

        <TAccordion
          v-for="(laboratory, laboratoryIndex) in bundleSelected.bundle"
          id="laboratories"
          :key="`laboratory-${laboratoryIndex}`"
          open
          without-border-bottom
        >
          <template #header>
            <div class="flex items-center">
              <div class="flex items-center gap-3 flex-1">
                <Img
                  :src="laboratory.laboratoryLogo" :alt="`Logo do laboratório ${laboratory.laboratoryName}`"
                  classes="w-10 h-10 rounded-lg"
                />

                <span class="text-base font-bold text-primary-50">
                  {{ laboratory.laboratoryName }}
                </span>
              </div>

              <span class="text-primary-40 text-base leading-6">
                {{ laboratory.procedures.length }} de {{ useProcedures().procedures.value.length }} exames
              </span>
            </div>
          </template>

          <template #content>
            <ul
              v-for="group in filterGroupsByLaboratory(laboratoryIndex)"
              :key="`group-${laboratoryIndex}-${group.index}`"
              class="border-b border-b-neutral-60 xl:px-8 px-4"
            >
              <span
                v-for="scheduleGroup in group.scheduleGroups"
                :key="scheduleGroup.procedureDisplaySlug"
                class="flex flex-col py-2 text-primary-20 leading-6 text-base"
              >
                {{ scheduleGroup.procedureName }}
              </span>

              <li class="flex flex-col xl:flex-row flex-wrap">
                <ProcedureBranchListItem
                  :laboratory="laboratory"
                  :group-index="group.index"
                  :hide-change-branch="hideChangeBranch"
                  @select-branch="handleSelectBranch"
                />
              </li>
            </ul>
          </template>
        </TAccordion>
      </div>

      <div class="xl:px-8 px-4 fixed bottom-0 w-full py-4 border border-top-neutral-60 bg-white">
        <Button
          class="w-fit"
          :disabled="goToCheckoutDisabled"
          @click="goToCheckout"
        >
          Agendar exames
        </Button>
      </div>
    </template>
  </SideSheet>
</template>
