function _parseHourToInt(hour: string): number {
  return Number.parseInt(hour.replace(':', ''))
}

const PERIODS = [
  { value: '02:00 às 04:00', text: '02:00 às 04:00' },
  { value: '04:00 às 06:00', text: '04:00 às 06:00' },
  { value: '06:00 às 08:00', text: '06:00 às 08:00' },
  { value: '08:00 às 10:00', text: '08:00 às 10:00' },
  { value: '10:00 às 12:00', text: '10:00 às 12:00' },
  { value: '12:00 às 14:00', text: '12:00 às 14:00' },
  { value: '14:00 às 16:00', text: '14:00 às 16:00' },
  { value: '16:00 às 18:00', text: '16:00 às 18:00' },
  { value: '18:00 às 20:00', text: '18:00 às 20:00' },
  { value: '20:00 às 22:00', text: '20:00 às 22:00' },
]

const DEFAULT_SLOTS = [
  { value: '08:00 às 10:00', text: '08:00 às 10:00' },
  { value: '10:00 às 12:00', text: '10:00 às 12:00' },
  { value: '12:00 às 14:00', text: '12:00 às 14:00' },
  { value: '14:00 às 16:00', text: '14:00 às 16:00' },
  { value: '16:00 às 18:00', text: '16:00 às 18:00' },
]

interface IOpeningHours {
  start: string
  end: string
}

export default function (openingHours: IOpeningHours) {
  if (!openingHours)
    return DEFAULT_SLOTS

  const start = _parseHourToInt(openingHours.start)
  const end = _parseHourToInt(openingHours.end)

  const result = PERIODS.filter((period) => {
    const [periodStartStr, periodEndStr] = period.value.split('às')

    const periodStart = _parseHourToInt(periodStartStr.trim())
    const periodEnd = _parseHourToInt(periodEndStr.trim())

    return periodStart >= start && periodEnd <= end
  })

  const firstAttributionStartStr = result[0].value.split('às')[0]
  const lastAttributionEndStr = result[result.length - 1].value.split('às')[1]

  const openingDifference = _parseHourToInt(openingHours.start) - _parseHourToInt(firstAttributionStartStr)
  const closingDifference = _parseHourToInt(openingHours.end) - _parseHourToInt(lastAttributionEndStr)

  if (openingDifference !== 0) {
    result[0].value = `${openingHours.start} às${result[0].value.split('às')[1]}`.trim()
    result[0].text = `${openingHours.start} às${result[0].value.split('às')[1]}`.trim()
  }

  if (closingDifference !== 0) {
    result[result.length - 1].value = `${result[result.length - 1].value.split('às')[0]}às ${openingHours.end}`
    result[result.length - 1].text = `${result[result.length - 1].value.split('às')[0]}às ${openingHours.end}`
  }

  return result
}
