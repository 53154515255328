<script setup lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  locale: {
    type: String,
    default: 'pt',
  },
  minDate: {
    type: Date,
    default: new Date(new Date().setDate(new Date().getDate() + 3)),
  },
  disabledWeekDays: {
    type: Array<number>,
    required: true,
  },
  disabledDates: {
    type: Array as PropType<Date[]>,
    default: () => [7],
  },
  placeholder: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue', 'selectDate'])

const { $filters } = useNuxtApp()

const datepicker = ref()

const show = ref(false)

function updateCalendar() {
  if (show.value)
    closeCalendar()
  else
    openCalendar()
}

function openCalendar() {
  show.value = true
}

onClickOutside(datepicker, closeCalendar)

function closeCalendar() {
  show.value = false
}

function handleSelectDate() {
  datepicker.value.selectDate()

  closeCalendar()
}

const dateFormatted = ref('')

function updateDate(dateString: any) {
  dateFormatted.value = dateString.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })

  closeCalendar()

  emit('selectDate', dateString)
  emit('update:modelValue', dateString)
}

onBeforeMount(() => {
  dateFormatted.value = $filters.formatDateEnToBr(props.modelValue)
})
</script>

<template>
  <div>
    <div class="relative h-[56px]" @click.stop>
      <input
        :id="id"
        type="text"
        class="py-4 peer h-full w-full rounded-lg border border-t-transparent bg-transparent duration-200 px-3 font-sans text-sm font-bold text-neutral-40 outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-t-transparent focus:outline-0"
        :class="{
          'border-primary-50 placeholder-shown:border-t-primary-50': show,
          'border-neutral-40 disabled:border-neutral-60 placeholder-shown:border-t-neutral-40': !show,
        }"
        :value="dateFormatted"
        :placeholder="props.placeholder"
        :disabled="show || props.disabled"
        @click="updateCalendar"
      >

      <span
        class="before:content[' '] focus:-top-[6px] after:content[' '] pointer-events-none absolute left-0 flex h-full text-neutral-40 w-full select-none text-sm font-normal leading-tight transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-neutral-60 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-neutral-60 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:mt-0 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-disabled:peer-placeholder-shown:text-neutral-60 duration-100 before:duration-100 after:duration-100"
        :class="{
          'text-primary-50': show,
          'text-neutral-40': !show,
          '-top-[1px] peer-disabled:after:border-transparent peer-disabled:before:border-transparent peer-disabled:text-transparent':
            !dateFormatted.length,
          '-top-[6px] peer-disabled:after:border-primary-50 peer-disabled:before:border-primary-50 peer-disabled:text-primary-50':
            dateFormatted.length,
        }"
      >
        {{ props.label }}
      </span>

      <Icon
        name="mdi:calendar"
        class="absolute rounded-full cursor-pointer text-neutral-30 bg-neutral-90 p-2 w-8 h-8 top-2.5 right-2"
        @click="updateCalendar"
      />
    </div>

    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
      mode="out-in"
    >
      <VueDatePicker
        v-if="show"
        ref="datepicker"
        :locale="props.locale"
        :model-value="props.modelValue"
        :day-names="['S', 'T', 'Q', 'Q', 'S', 'S', 'D']"
        :disabled-dates="disabledDates"
        :max-date="new Date(new Date().setDate(new Date().getDate() + 91))"
        :enable-time-picker="false"
        class="mt-2 z-50"
        calendar-class-name="text-xs"
        preview-format=""
        calendar-cell-class-name="calendar__cell"
        inline
        auto-apply
        :min-date="minDate"
        :disabled-week-days="props.disabledWeekDays"
        @update:model-value="updateDate"
        @closed="closeCalendar"
      >
        <template #calendar-header="{ day }">
          <div class="font-light">
            {{ day }}
          </div>
        </template>
        <template #day="{ day }">
          <div class="font-light">
            {{ day }}
          </div>
        </template>
        <template #action-preview>
          &nbsp;
        </template>
        <template #action-buttons>
          <button
            class="text-primary-50 text-sm font-medium px-2 py-2 mr-1"
            type="button"
            @click="closeCalendar"
          >
            Cancelar
          </button>
          <button
            class="text-primary-50 text-sm font-medium px-2 py-2"
            type="button"
            @click="handleSelectDate"
          >
            Ok
          </button>
        </template>
      </VueDatePicker>
    </transition>
  </div>
</template>

<style lang="postcss">
.dp__theme_light {
  --dp-background-color: #f9f5fb;
  --dp-text-color: #1d1b20;
  --dp-hover-color: #f3f3f3;
  --dp-primary-color: #730099;
}

.dp__main {
  position: absolute;
  left: 0;
  z-index: 99;
}

.calendar__cell {
  border-radius: 50%;
}
</style>
