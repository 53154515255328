<script setup lang="ts">
import type { IBranch, IBundleItem } from '@/modules/procedure/types/bundle'

const props = defineProps({
  laboratory: {
    type: Object as PropType<IBundleItem>,
    required: true,
  },

  hideChangeBranch: {
    type: Boolean,
    default: false,
  },

  groupIndex: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['selectBranch'])

const branchSelected = ref()

const branches = computed(() => props.laboratory.branches.map(branch => ({
  ...branch,
  procedures: props.laboratory.procedures,
  laboratoryId: props.laboratory.laboratoryId,
})))

function laboratoryCollectTypeText(branch: IBranch) {
  return branch.laboratoryBranchAtHome ? 'Coleta domiciliar' : branch.laboratoryBranchName
}

function laboratoryOpeninHoursText(branch: IBranch) {
  return branch.laboratoryBranchAtHome
    ? 'Agende o melhor dia para o laboratório coletar o exame na sua casa!'
    : `${branch.laboratoryBranchAddressOne}, ${branch.laboratoryBranchNumber} -
          ${branch.laboratoryBranchDistrict}, ${branch.laboratoryBranchCity}`
}

const branchesFiltered = computed(() => {
  if (branchSelected.value) {
    return branches.value.filter(branch => branch.laboratoryBranchId === branchSelected.value)
  }

  const branchesResponse = branches.value.filter(branch => !branch.laboratoryBranchAtHome)

  const branchAtHome = branches.value.find(branch => !!branch.laboratoryBranchAtHome)

  if (branchAtHome)
    branchesResponse.unshift(branchAtHome)

  return branchesResponse
})

const { schedules } = useSchedule()

watch(
  branchSelected,
  (newBranchSelected, oldBranchSelected) => {
    if (newBranchSelected) {
      emit('selectBranch', { ...branches.value.find(branch => branch.laboratoryBranchId === newBranchSelected), groupIndex: props.groupIndex })
    }
    else {
      emit('selectBranch', { ...branches.value.find(branch => branch.laboratoryBranchId === oldBranchSelected), groupIndex: props.groupIndex }, { remove: true })
    }

    if (schedules.value[oldBranchSelected])
      delete schedules.value[oldBranchSelected]
  },
)

onNuxtReady(() => {
  if (branches.value.length === 1)
    branchSelected.value = branches.value[0].laboratoryBranchId
})
</script>

<template>
  <li
    v-for="branch in branchesFiltered"
    :key="branch.laboratoryBranchId"
    class="w-full"
  >
    <div>
      <div class="flex items-start py-3">
        <label
          :for="`${branch.laboratoryBranchId}-${groupIndex}`"
          :class="{
            'bg-white duration-300': branchSelected !== branch.laboratoryBranchId,
          }"
          class="flex-1 group flex items-center cursor-pointer h-full group-hover:border-primary-20 text-primary-20 duration-300"
        >
          <input
            :id="`${branch.laboratoryBranchId}-${groupIndex}`"
            v-model="branchSelected"
            :value="branch.laboratoryBranchId"
            :checked="branches.length === 1"
            :name="`${laboratory.laboratoryName}-${groupIndex}`"
            type="radio"
            class="peer mr-4 appearance-none bg-white border-2 border-primary-20 cursor-pointer rounded-full w-6 h-6 relative peer shrink-0 checked:bg-white checked:text-white checked:border-2 checked:border-primary-50 checked:before:content-[''] before:absolute before:inset-1 before:bg-white checked:before:bg-primary-50 before:rounded-full flex justify-center items-center text-base group-hover:border-primary-50 duration-300"
          >

          <div class="flex flex-col peer-checked:text-primary-50">
            <div class="flex items-center">
              <Icon v-if="branch.laboratoryBranchAtHome" name="mdi:home" class="w-5 h-5 mr-1" />

              <span class="font-extrabold">
                {{ laboratoryCollectTypeText(branch) }}
              </span>
            </div>

            <span class="font-normal">
              {{ laboratoryOpeninHoursText(branch) }}
            </span>

            <span
              v-if="branch.laboratoryBranchOpeningHours"
              class="font-bold"
            >
              {{ $filters.openingHours(branch.laboratoryBranchOpeningHours) }}
            </span>
          </div>
        </label>

        <button
          v-if="branchSelected && !hideChangeBranch"
          class="text-primary-60 text-xs font-semibold"
          @click="branchSelected = undefined"
        >
          Trocar unidade
        </button>
      </div>

      <ProcedureBranchListItemSchedule
        v-if="branchSelected === branch.laboratoryBranchId"
        :group-index="groupIndex"
        :branch="branch"
      />
    </div>
  </li>
</template>
